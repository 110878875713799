<template>
  <div class="wait" v-html="text"></div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: "OrderConfirm",

    data() {
      return {
        text: 'Ожидание ответа платежной системы.'
      }
    },

    methods: {
      getPaymentInfo() {
        const url = '/api/checkPayment?paymentId=' + this.$route.query.paymentId

        axios.post(url)
          .then(function(response) {
            if (response.data.success) {
              location.href = '/cart?ty=true'
            } else {
              setTimeout(() => {
                this.getPaymentInfo()
              }, 500)
            }
          })
          .catch(function (error) {
            console.log(error);
          })

      }
    },

    mounted() {
      this.getPaymentInfo()
    }
  }
</script>

<style scoped>

</style>